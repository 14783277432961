import { of as observableOf, throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Contact, Tag } from '../models/contact.model';
import { ContactForAllReq } from '../models/contactAllReq.model';
import { ContactExport } from '../shared/enum';
import { Note } from '../../shared/note.model';
import { IBaseService } from '../interfaces/base-interface'
import { BaseDjangoService } from './base-django.service';
import { AuthenticationService } from './authentication.service';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ContactService extends BaseDjangoService implements IBaseService {

  currentContact: Contact;
  currentNumber: string;
  contacts: Contact[];
  currentContactUpdated: EventEmitter<ContactExport> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) {
    super(authenticationService);
    this.apiUrl += '/contact';
    this.subRoute = '/note';
    this.defaultSearchParam = 'subscriber';
  }

  setCurrentContact(contact: any): void {
    let key: ContactExport;
    this.currentContact = null;
    this.currentNumber = null;
    if (typeof contact === 'string') {
      key = ContactExport.number;
      this.currentNumber = contact;
      this.currentContact = null;
      // } else if (contact instanceof Contact) {
    } else if (typeof contact === 'object' && contact != null) {
      key = ContactExport.contact;
      this.currentContact = Contact.toContact(contact);
      this.currentNumber = null;
    }
    this.currentContactUpdated.emit(key);
  }

  getAll(): Promise<Contact[]> {
    if (!this.contacts) {
      const options = this.getOptions();

      return this.http.get(this.apiUrl + '/', options)
        .toPromise()
        .then((res) => {
          const contacts = res as Contact[];
          for (let i = 0; i < contacts.length; i++) {
            const contact = Contact.toContact(contacts[i]);
            contacts[i] = this.unpackTags(contact);
          }
          this.contacts = contacts;
          contacts.sort((left: Contact, right: Contact) => {
            let diff: number;
            const left_date = new Date(left.create_date);
            const right_date = new Date(right.create_date);
            diff = left_date < right_date ? -1 : left_date > right_date ? 1 : 0;
            return diff;
          })
            .reverse();
          return this.contacts;
        })
        .catch((err) => observableThrowError(err).toPromise());
    } else {
      return observableOf(this.contacts).toPromise();
    }

    // .map((res) => res as Contact[]);
  }

  getById(id: string): Promise<Contact[]> {
    const options = this.getOptions();

    return this.http.get(this.apiUrl + '/' + id, options)
      .toPromise()
      .then((res) => {
      
        
        const temp = res as Contact[];
        const result: Contact[] = [];
        
        
        if (result.hasOwnProperty('length')) {
          // for some stupid reason Put returns a list of objects
          // whereas post returns one
          // result = result as Contact[];
          for (let i = 0; i < temp.length; i++) {
            const contact = Contact.toContact(temp[i]);
            result.push(this.unpackTags(contact));
          }
        }
        return result;
      })
      .catch((err) => observableThrowError(err).toPromise());
  }

  getByParam(value: string, key?: string, useFilter?: boolean, inactive?: boolean): Observable<Contact[]> {
    if (!value) {
      return observableOf([]);
    }
    key = key == null ? this.defaultSearchParam : key;
    let url: string;
    if (!useFilter) {
      url = this.apiUrl + '?' + key + '=' + value;
    } else {
      url = this.apiUrl + '?' + this.filter_key + '=' + key + '&' + this.filter_by + '=' + value;
    }

    if (inactive) {
      url += 'inactive=true';
    }

    const options = this.getOptions();

    return this.http.get(url, options).pipe(
      map((res) => {
        // should probably use a different object
        const temp = res as Contact[];
        const result: Contact[] = [];

        if (result.hasOwnProperty('length')) {
          // for some stupid reason Put returns a list of objects
          // whereas post returns one
          // result = result as Contact[];
          for (let i = 0; i < temp.length; i++) {
            const contact = Contact.toContact(temp[i]);
            result.push(this.unpackTags(contact));
          }
        }
        return result;
      }),
      catchError((err) => observableThrowError(err))
    );
  }

  getByParamPromise(value: string, key?: string, useFilter?: boolean, inactive?: boolean): Promise<Contact[]> {
    if (!value) {
      return observableOf([]).toPromise();
    }
    key = key == null ? this.defaultSearchParam : key;
    let url: string;
    if (!useFilter) {
      url = this.apiUrl + '?' + key + '=' + value;
    } else {
      url = this.apiUrl + '?filter=' + key + '&filter_val=' + value;
    }

    if (inactive) {
      url += 'inactive=true';
    }

    const options = this.getOptions();

    return this.http.get(url, options)
      .toPromise()
      .then((res) => {
        const temp = res as Contact[];
        const result: Contact[] = [];
        // should probably use a different object
        if (result.hasOwnProperty('length')) {
          // for some stupid reason Put returns a list of objects
          // whereas post returns one
          // result = result as Contact[];
          for (let i = 0; i < temp.length; i++) {
            const contact = Contact.toContact(temp[i]);
            result.push(this.unpackTags(contact));
          }
        }
        return result;
      })
      .catch((err) => observableThrowError(err).toPromise());
  }

  getByParamPromiseAllReq(value: string, key?: string, useFilter?: boolean, inactive?: boolean): Promise<ContactForAllReq[]> {
    if (!value) {
      return observableOf([]).toPromise();
    }
    key = key == null ? this.defaultSearchParam : key;
    let url: string;
    if (!useFilter) {
      url = this.apiUrl + '?' + key + '=' + value;
    } else {
      url = this.apiUrl + '?filter=' + key + '&filter_val=' + value;
    }

    if (inactive) {
      url += 'inactive=true';
    }

    const options = this.getOptions();

    return this.http.get(url, options)
      .toPromise()
      .then((res) => {
        const temp = res as ContactForAllReq[];
        const result: ContactForAllReq[] = [];
        // should probably use a different object
        if (result.hasOwnProperty('length')) {
          // for some stupid reason Put returns a list of objects
          // whereas post returns one
          // result = result as Contact[];
          for (let i = 0; i < temp.length; i++) {
            const contact = ContactForAllReq.toContact(temp[i]);
            result.push(this.unpackTagsForAllReq(contact));
          }
        }        
        return result;
      })
      .catch((err) => observableThrowError(err).toPromise());
  }

  save(contact: Contact): Promise<Contact> {
    let callback: any;
    let url = this.apiUrl;
    let payload: string;
    // deleting notes as the save breaks sometimes if it's there
    const notes = _.cloneDeep(contact.notes);
    delete contact.notes;
    contact = this.packTags(contact);
    const options = this.getOptions();

    if (!!contact.contact_id) {
      url += '/' + contact.contact_id;
      delete contact.contact_id;
      payload = JSON.stringify(contact);
      callback = this.http.put(url, payload, options);
    } else {
      payload = JSON.stringify(contact);
      callback = this.http.post(url, payload, options);
    }
    return callback
      .toPromise()
      .then((res) => {
        let result = res;
        if (result.hasOwnProperty('length')) {
          // for some stupid reason Put returns a list of objects
          // whereas post returns one
          result = result[0] as Contact;
        } else {
          result = result as Contact;
        }
        let ret_contact: Contact;
        if (result) {
          ret_contact = this.unpackTags(result);
          const idx = this.contacts.findIndex(c => c.contact_id === ret_contact.contact_id);
          if (idx > -1) {
            this.contacts[idx] = ret_contact;
          } else {
            this.contacts.push(ret_contact);
          }
          // adding back notes if we have it
          if (notes) {
            contact.notes = notes;
          }
        }
        this.currentContact = ret_contact;
        return ret_contact;
      })
      .catch((err) => observableThrowError(err).toPromise());
  }


  // 25/12/24 - shopify 

  saveShopify(contact: Contact): Promise<Contact> {
    let callback: any;
    let url = this.apiUrl;
    let payload: string;
    // deleting notes as the save breaks sometimes if it's there
    const notes = _.cloneDeep(contact.notes);
    delete contact.notes;
    contact = this.packTags(contact);
    const options = this.getOptions();

    if (!!contact.contact_id) {
      url += '/' + contact.contact_id;
      delete contact.contact_id;
      payload = JSON.stringify(contact);
      callback = this.http.put(url, payload, options);
    } else {
      payload = JSON.stringify(contact);
      callback = this.http.post(url, payload, options);
    }
    return callback
      .toPromise()
      .then((res) => {
        let result = res;
        if (result.hasOwnProperty('length')) {
          // for some stupid reason Put returns a list of objects
          // whereas post returns one
          result = result[0] as Contact;
        } else {
          result = result as Contact;
        }
        let ret_contact: Contact;
        if (result) {
          ret_contact = this.unpackTags(result);
          const idx = this.contacts.findIndex(c => c.contact_id === ret_contact.contact_id);
          if (idx > -1) {
            this.contacts[idx] = ret_contact;
          } else {
            this.contacts.push(ret_contact);
          }
          // adding back notes if we have it
          if (notes) {
            contact.notes = notes;
          }
        }
        this.currentContact = ret_contact;
        return ret_contact;
      })
      .catch((err) => observableThrowError(err).toPromise());
  }


  // 25/12/24 - shopify 



  saveNote(contact_id: string, note: Note): Promise<Note> {
    let url = this.apiUrl;
    let payload: string;
    let promise: any;
    console.log(url , contact_id , note , "note");
    
    const options = this.getOptions();
    // need to add on mime-type cause reasons
    if (note && note.note_text) {
      note.note_text['mime-type'] = 'text/plain';
    }
    // need to remove the user from the note....
    if (note.user) {
      delete note.user;
    }

    if (!contact_id) {
      promise = observableThrowError('Related Contact was not supplied');
    } else {
      url += '/' + contact_id + this.subRoute;
      payload = JSON.stringify(note);
      promise = this.http.post(url, payload, options);
    }

    return promise
      .toPromise()
      .then((res) => res as Note)
      .catch((err) => observableThrowError(err).toPromise());
  }

}
